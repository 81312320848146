.Four-picture-section-wrapper1 {
  width: 90.277777778vw;
  height: 30vw;
  padding: 0 5vw;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 20px;
  background: var(--White, #f1f2f2);
}

.Four-picture-section-wrapper1 .item1 {
  width: 29.166666667vw;
  height: 34.444444444vw;
  position: relative;
  top: 6vw;
  z-index: 1;
  flex-shrink: 0;
  border-radius: 8px;
  background: transparent;
}

.Four-picture-section-wrapper3 .item1 {
    position: relative;
    top: -4vw;
  width: 29.166666667vw;
  height: 34.444444444vw;
  flex-shrink: 0;
  background: #6e6e6e;
}
.Four-picture-section-wrapper2 {
    margin-top: 3vw;
     
}

.Four-picture-section-wrapper2 .item1 {
  width: 53.472222222vw;
  height: 19.236111111vw;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Grey, #888989);
  box-shadow: 57px 80px 0px 20px #fff;
}

@media screen and (max-width: 767px) {
    .Four-picture-section-wrapper1 {
        width: 88.8vw;
        height: 53.333333333vw;
    
      }
      
      .Four-picture-section-wrapper1 .item1 {
        width: 36.133333vw;
        height: 53.666667vw;
        position: absolute;
        right: 0;
        top: 38px;
    }
      
      .Four-picture-section-wrapper1 .item2 {
        width: 78.133333333vw;
        height: 42.666666667vw;
   
      }
      
      .Four-picture-section-wrapper3 .item1 {
          position: relative;
          top: 30vw;
          width: 70.666666667vw;
          height: 66.933333333vw;
        border-radius: 10px;
        background: #6e6e6e;
      }
      .Four-picture-section-wrapper2 {
          margin-top: 3vw;
           
      }
      
      .Four-picture-section-wrapper2 .item1 {
        width: 65.6vw;
        height: 31.733333333vw;
        flex-shrink: 0;
        border-radius: 8px;
     background:transparent;
        box-shadow: 25vw  80px 0px 20px #fff;
      }
      
}