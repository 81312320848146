* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

video {
  border-radius: 1rem;
}
body {
  overflow-y: hidden;
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

body {
  background-color: #f1f2f2;
}

p {
  /* text-align: justify; */
  font-family: Telegraf;
  font-size: 1.6667vw;
  font-style: normal;
  font-weight: 500;
  /* line-height: 30px; 125% */
  line-height: normal;
}
.bg-lightgreen {
  background: var(--Cobalt, #aebfbf) !important;
}
.fw-bold-500 {
  font-weight: 500 !important;
}
.fw-bold-600 {
  font-weight: 600 !important;
}

.font-telegraf {
  font-family: Telegraf, sans-serif !important;
}

.font-antonio {
  font-family: Antonio, sans-serif !important;
}

.text-justify {
  text-align: justify;
}
.min-vh-110 {
  min-height: 110vh;
}

.btn:hover svg {
  filter: invert(1) !important;
}
.banner-section {
  height: 150vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  padding-top: 10rem;
  position: relative;
}

.banner-section .mobh1 {
  font-family: Telegraf;
  font-weight: 700;
}
.home-dynamics {
  width: 80%;
  top: 26%;
}
.banner-section .stroked-identity {
  color: #f1f2f2;
  -webkit-text-stroke: 0.03em #f1f2f2;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1.5s;
  line-height: 1;
}
.stroked-identity-fill {
  color: #f1f2f2;
  -webkit-text-stroke: 0.03em #f1f2f2;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1.5s;
  line-height: 1;
}

.banner-section .stroked-identity::before {
  content: attr(data-content);
  -webkit-text-fill-color: #202020;
  -webkit-text-stroke: 0;
  position: absolute;
  opacity: 1;
  transition: opacity 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.banner-section .stroked-identity-fill::before {
  content: attr(data-content);
  -webkit-text-fill-color: #f1f2f2;
  -webkit-text-stroke: 0;
  position: absolute;
  opacity: 1;
  transition: opacity 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.banner-section .stroked-identity-wrapper {
  font-family: Telegraf-UltraBold;
  font-size: 15vw;
  letter-spacing: 1.7rem;
  font-weight: 700;
}

.banner-section .stroked-identity:hover::before {
  opacity: 0;
}

.banner-section h2 {
  color: #f1f2f2;
  /* font-size: 224px; */
  font-size: 4vw;
  font-family: Telegraf;
  font-weight: 500;
  word-wrap: break-word;
}

.banner-section p {
  text-align: justify;
  width: 11.54vw;
  font-family: Telegraf;
  font-size: 1.67vw;
  font-weight: 500;
}

.banner-section hr {
  width: 10vw;
  position: absolute;
  top: 29%;
  left: 103%;
}

.navbar-nav a {
  color: #f1f2f2;
  font-size: 20px;
  font-family: Telegraf;
  font-weight: 500;
  word-wrap: break-word;
}

.navbar-expand-lg .navbar-nav {
  gap: 32px;
}
.talk-btn:hover {
  background-color: #f1f2f2;
  color: #202020 !important;
  transition: all 0.3s ease;
}
.talent-slider-container {
  height: 100vh;
}
.talk-btn:hover svg {
  filter: invert(1);
}
.contact-talk-btn:hover svg {
  filter: invert(1);
}
.contact-talk-btn:hover {
  background-color: #f1f2f2;
  color: #202020;
  transition: all 0.3s ease;
}

.Home-Connecting {
  height: auto;
  padding-top: 10rem;
  /* overflow: auto; */
  padding-bottom: 5rem;
  margin-top: -1px;
}

.connecting-images {
  width: 80%;
}

.Home-Connecting h1 {
  font-family: Telegraf;
  font-size: 128px;
  font-style: normal;
  font-weight: 500;
  line-height: 140px;
  /* 114.063% */
  text-transform: uppercase;
}
.floating-images {
  display: block;
}

.beside-text {
  font-weight: 500;
  position: relative;
  font-size: 24px;
  width: 176px;
  top: 10px;
  left: 17px;
  line-height: normal;
}

.text1 {
  line-height: 100px;
}

.Achieve-text {
  font-weight: 500;
  font-size: 24px;
  width: 250px;

  line-height: normal;
}

.brand-text {
  font-size: 8vw;
}

.with-text {
  font-size: 5vw;
}
.talent-banner {
  height: auto;
}
.talent-section {
  height: auto;
  padding-top: 7rem;
  overflow: hidden;
}

.talent-section h1 {
  font-family: Antonio;
  font-size: 128px;
  font-style: normal;
  font-weight: 700;
  line-height: 146px;
  /* 114.063% */
  text-align: left;
}

.talent-section .talent-images {
  padding: 1rem 0.5rem;
}

.talent-text {
  text-align: justify;
  font-family: Telegraf;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* 125% */
}

.justified-text {
  color: #f1f2f2;
  text-align: justify;
  font-family: Telegraf;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px;
  /* 125% */
}

.talent-section .talent-name {
  position: relative;
  top: -3rem;
  left: 1rem;
  font-family: Telegraf;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.talent-section .col-6.col-lg-6.col-md-6.g-0 {
  filter: grayscale(1);
}

.talent-section hr {
  position: relative;
  right: 4%;
  width: 60%;
  top: 10%;
  background: #aebfbf;
  height: 2px;
  border: none;
  margin-top: 2vw;
}

.talent-section p {
  font-family: Telegraf;
  font-size: 1.6667vw;
  font-weight: 500;
  line-height: -0.33vw;
  letter-spacing: 0em;
  text-align: justify;
}

.stroked {
  color: black;
  -webkit-text-stroke: 0.05em black;
  transition: all cubic-bezier(0.025, 0.82, 0.165, 1) 1.5s;
  line-height: 1;
}

.stroked::before {
  content: attr(data-content);
  -webkit-text-fill-color: #f1f2f2;
  -webkit-text-stroke: 0;
  position: absolute;
  opacity: 1;
  transition: opacity 1.3s cubic-bezier(0.025, 0.82, 0.125, 1);
}

.stroked-wrapper {
  font-family: Telegraf-UltraBold;
  font-size: 15vw;
  font-weight: 700;
  line-height: 190px;
  font-style: normal;
  /* 84.821% */
  letter-spacing: 11.2px;
  transition: all 0.3s ease;
}

.stroked-wrapper :hover::before {
  opacity: 0;
}

.stroked-white {
  color: #f1f2f2;
  -webkit-text-stroke: 0.05em #f1f2f2;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1.5s;
  line-height: 1;
}

.stroked-white::before {
  content: attr(data-content);
  -webkit-text-fill-color: #202020;
  -webkit-text-stroke: 0;
  position: absolute;
  opacity: 1;
  transition: opacity 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.stroked-white-wrapper {
  font-family: Telegraf-UltraBold;
  font-size: 15vw;
  letter-spacing: 1.7rem;
  font-weight: 900;
  padding-top: 100px;
  padding-bottom: 100px;
}

.stroked-white:hover::before {
  opacity: 0;
}

.what-we-do {
  height: auto;
  padding-top: 7rem;
  overflow: hidden;
}

.what-we-do h1 {
  font-family: Antonio;
  font-size: 128px;
  font-style: normal;
  font-weight: 700;
  line-height: 146px;
  /* 114.063% */
  text-align: left;
}

.what-we-do hr {
  position: relative;
  float: right;
  top: 6%;
  background: #aebfbf;
  height: 2px;
  border: none;
  margin-top: 40px;
}

/* .what-we-do p {
  font-family: Telegraf;
  font-size: 1.6667vw;
  font-weight: 500;
  line-height: 1.67vw;
  letter-spacing: 0em;
  text-align: justify;
} */
.what-we-do .outlined {
  font-family: Telegraf;
  font-size: 2.7778vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  padding: 9px 18px;
  align-items: center;
  border-radius: 21px;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.what-we-do .outlined:hover {
  background: #202020;
  color: #f1f2f2;
}

.achieve {
  height: auto;
  background: linear-gradient(to bottom, #f1f2f2 50%, #202020 50%);
}

.achieve .bg-heading {
  opacity: 0.7;
  color: #202020;
  font-family: Antonio;
  font-size: 23.3333vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.achieve .coverup {
  /* position: relative; */
  border-radius: 11.1111vw 11.1111vw 0px 0px;
  background: #202020;
  /* top: -16vh; */
}

.achieve .let-start {
  color: #f1f2f2;
  font-family: Telegraf;
  font-size: 128px;
  font-style: normal;
  font-weight: 500;
  line-height: 111.063%;
  /* 114.063% */
  letter-spacing: 6.4px;
}

.achieve .something {
  font-family: Antonio;
  font-size: 128px;
  font-style: normal;
  font-weight: 700;
  line-height: 91.063%;
  letter-spacing: 6.4px;
}

.achieve .every {
  color: var(--White, #f1f2f2);
  font-size: 128px;
  font-style: normal;
  font-weight: 700;
  line-height: 114.063%;
  letter-spacing: 6.4px;
}

.big-heading {
  color: var(--White, #f1f2f2);
  font-family: Antonio;
  font-size: 128px;
  font-style: normal;
  font-weight: 700;
  line-height: 120px;
  /* 114.063% */
}

.section-padding {
  padding-top: 7rem;
}

.custom-padding {
  padding-left: 4.375rem !important ;
  padding-right: 4.375rem !important ;
}

.project .image-row {
  height: 29vw;
}

.project-image-scroller {
  max-height: 55vh;
  height: 100%;
  min-height: 54vh;
}

.project-image-scroller img {
  width: auto;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.brand-slider {
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.slider-image {
  display: flex;
  width: 100%;
  height: 12.5vw;
  padding: 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid #f1f2f2;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -20px !important;
}

/* .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets */
.swiper-pagination-bullet {
  width: 12.5px !important;
  height: 62.5px !important;
  transform: rotate(-90deg) !important;
  border-radius: 6.25px !important;
  border: 2px solid #f1f2f2;
  opacity: 1;
  margin-left: 60px !important;
}

.swiper-pagination-bullet-active {
  background-color: #f1f2f2 !important;
}

.contact {
  overflow: hidden;
}

.contact h1 {
  font-size: 4.44vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.contact h2 {
  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.btn-outline-light:hover {
  color: #202020 !important;
}

.contact p {
  color: #f1f2f2;
  text-align: right;
  font-family: Telegraf;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.line {
  position: absolute;
  width: 0.1%;
  background: white;
  left: 51%;
  top: 10%;
}

.scrolly-img .overlay {
  opacity: 0;
  display: none;
}

.scrolly-img .overlay button {
  border-radius: 20px;
}

.scrolly-img:hover .overlay {
  opacity: 1;
  position: absolute;
  /* top: 50%;
  left: 38%; */
  /* right: 45%; */
  transition: all 1s ease-in-out;
  z-index: 999;
  display: block;
}

.bolding-class {
  font-weight: 500 !important;
  font-size: 220px;
}

.scrolly-img:hover img {
  filter: blur(20px);
  transition: all 0.7s ease-in-out;
}

.talk-to-us {
  height: 100vh;
}

.btn-outline-light:hover svg {
  color: #202020;
}

.talent-marquee {
  color: var(--White, #f1f2f2);
  font-family: Antonio;
  font-size: 23.33vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.talent-slider .item img {
  min-height: 500px;
  max-height: 500px;
}

.vh-100 {
  height: 100vh;
}

.what-we-section-padding {
  padding-left: 5vw !important;
  padding-right: 5vw !important;
  padding-top: 3vw !important;
}

.line-black {
  position: relative;
  width: 0.1%;
  background: black;
  top: -40%;
}

.what-we-do-line-black {
  position: relative;
  width: 1.5%;
  background: black;
  top: -10%;
  left: 25%;
}

.heading-2 {
  color: var(--Black, #202020);
  font-family: Telegraf;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.other-work .nav-item a {
  color: var(--Black, #202020);
  font-family: Telegraf;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.other-work .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: none;
}

.other-work .nav-tabs .nav-link {
  border: none;
}

.project-heading {
  text-align: justify;
  font-family: Telegraf;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}

.project-subheading {
  text-align: justify;
  font-family: Telegraf;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.project .image-row .scrolly-img img {
  height: 28vw;
}

.scroll-container {
  height: 100vh;
  overflow-y: auto;
  position: relative;
}

.section {
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.section {
  scroll-snap-type: y mandatory;
}

.section:first-child {
  scroll-snap-align: start;
}

.section:not(:first-child) {
  scroll-snap-align: center;
}
.nav-mob-number {
  color: var(--Cobalt, #aebfbf);
  font-family: "FONTSPRING DEMO - The Seasons";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.become-permium {
  margin-bottom: 20px;
  display: inline-flex;
  padding: 18px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.what-we-do-info {
  padding-top: 10rem;
}

.padding-t-10rem {
  padding-top: 10rem;
}

.accordion-button {
  background-color: #f1f2f2 !important;
  width: 102% !important;
}

.accordion-button:focus {
  box-shadow: none !important;
  border: none !important;
}

.stroked-white-Loading {
  font-family: Antonio;
  font-size: 16vw;
  letter-spacing: 1.7rem;
  font-weight: 900;
  padding-top: 100px;
  padding-bottom: 100px;
  stroke-width: 5px;
}

.stroked-white-Loading {
  color: #f1f2f2;
  -webkit-text-stroke: 0.05em #f1f2f2;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1.5s;
  line-height: 1;
}

.stroked-white-Loading::before {
  content: attr(data-content);
  -webkit-text-fill-color: #202020;
  -webkit-text-stroke: 0;
  position: absolute;
  opacity: 1;
  transition: opacity 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.stroked-purple-Loading {
  font-family: Antonio;
  font-size: 16vw;
  letter-spacing: 1.7rem;
  font-weight: 900;
  padding-top: 100px;
  padding-bottom: 100px;
  stroke-width: 5px;
}

.stroked-purple-Loading {
  color: #8b4e8e;
  -webkit-text-stroke: 0.05em #8b4e8e;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1.5s;
  line-height: 1;
}

.stroked-purple-Loading::before {
  content: attr(data-content);
  -webkit-text-fill-color: #202020;
  -webkit-text-stroke: 0;
  position: absolute;
  opacity: 1;
  transition: opacity 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.loading-number {
  color: var(--Cobalt, #aebfbf);
  font-family: FONTSPRING DEMO - The Seasons;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.loading-progress {
  height: 5px !important;
}

.contact-end-text {
  color: var(--White, #f1f2f2);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  /* 118.75% */
}

.number-text {
  color: var(--Cobalt, #aebfbf);
  font-family: "FONTSPRING DEMO - The Seasons";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* junaid working changing start */

.size-change {
  color: var(--Black, #202020);
  font-family: Telegraf-UltraBold;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.hover-image {
  position: relative;
}

.overlay-chnage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  backdrop-filter: blur(10px);
}

.hover-image:hover .overlay-chnage {
  opacity: 1;
}

.text-change {
  display: inline-flex;
  padding: 18px 36px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  font-weight: 400;
  font-size: 25px;
  font-family: Telegraf !important;
  border: 1px solid var(--Black, #202020);
  color: #000;
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  outline: none;
  background: none;
  /* border: 1px solid #000; */
  /* border: none; */
  /* font-size: 24px; */
  /* color: #000; */
  /* border-radius: 26px; */
}

.text-change:hover {
  background-color: var(--Black, #202020);
  color: #f1f2f2;
}

.demo a:hover {
  background: none !important;
}

.demo a:active {
  background: none !important;
}

.active {
  background: none !important;
}

.demo:active {
  background: none !important;
}

.input-placeholder::placeholder {
  margin-top: 20px !important;
  color: #f1f2f2 !important;
}

.input-placeholder {
  padding: 40px !important;
  margin-top: -2px !important;
  width: 100% !important;
  font-size: 26px !important;
  color: #f1f2f2 !important;
}

.input-placeholder::placeholder:active {
  display: none !important;
}

.step1 {
  padding-left: 5%;
}

.customhoverjunaid:hover {
  cursor: pointer;
  text-decoration: solid black underline;
}

.customhoverjunaid:active {
  cursor: pointer;
  text-decoration: solid black underline;
}

.customhoverjunaidtwo:hover {
  border-bottom: 1px solid white;
  cursor: pointer;
}

.filter-hover:hover {
  text-decoration: solid white underline;
  cursor: pointer;
}
/* junaid working changing end */

/* inline style changing Junaid */
.inline-one {
  width: 80% !important;
  margin-left: 20% !important;
}
.inline-two {
  width: 60% !important;
  margin-left: -5% !important;
}
.inline-three {
  margin-left: -12% !important;
}
.inline-four {
  width: 120% !important;
}
.inline-five {
  margin-left: -10%;
}
.inline-six {
  height: auto;
}
.inline-seven {
  margin-left: 12px !important;
  letter-spacing: 4px !important;
}
.inline-eight {
  margin-bottom: -20px !important;
}
.inline-nine {
  margin-top: 68px !important;
}
.inline-ten {
  letter-spacing: 7px !important;
}
.inline-eleven {
  width: 35% !important;
}
.inline-twelve {
  display: inline-flex !important;
  padding: 18px 36px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
}
.inline-thirteen {
  font-weight: 700 !important;
}
.inline-fourteen {
  letter-spacing: 2px !important;
}
.inline-fifteen {
  color: #aebfbf !important;
  font-size: 24px !important;
  font-weight: 400 !important;
}
.inline-sixteen {
  margin-top: -20px !important;
}
.inline-seventeen {
  word-spacing: 10px !important;
}
.inline-eighteen {
  height: 47% !important;
}
.inline-nineteen {
  padding-top: 9% !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 27.26px !important;
}
.inline-twenty {
  margin-top: 80px;
}
.inline-twentyone {
  margin-left: 50px !important;
}
.inline-twentytwo {
  width: 27% !important;
}
.inline-twentythree {
  height: 58vh;
}
.inline-twentyfour {
  margin-top: -10px !important;
}
.inline-twentyfive {
  margin-top: -10px !important;
  height: 2px !important;
  background-color: #616161 !important;
}
.inline-twentysix {
  margin-top: -44px !important;
}
.inline-twentyseven {
  margin-left: -15%;
}
.inline-twentyeight {
  margin-left: -25% !important;
}
.inline-twentynine {
  height: 105vh;
}
.inline-thirty {
  letter-spacing: 5px;
  line-height: 90%;
}
.inline-thirtyone {
  line-height: 150% !important;
}
.inline-thirtytwo {
  word-spacing: 1px;
  line-height: 35px;
  letter-spacing: -1px;
  width: 326px;
}
.inline-thirtythree {
  word-spacing: -1px;
  width: 333px;
}
.inline-image-one {
  width: 660px;
  height: 547px;
}
.inline-image-two {
  width: 660px;
  height: 610px;
}
.inline-image-three {
  width: 660px;
  height: 610px;
}
.inline-image-four {
  width: 660px;
  height: 547px;
}

.cookie-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CookieConsent {
  color: #fff;
  text-align: justify;
  font-family: Telegraf;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: transparent !important;
  border-radius: 100px;
  border: 1px solid #fff;
  width: 71.81vw !important;
  height: 9.93vw;
  display: flex;
  align-items: center !important;
  left: 15% !important;
  padding: 0 6vw;
  bottom: 5vw !important;
}

/* inline style changing Junaid */

/* navbar issues sloving */
.navbar-toggler:focus {
  box-shadow: none !important;
}
.brand-grids {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.custom-w-project-benner {
  width: 30%;
}

.custom-project-h1.project-heading {
  width: 25%;
}
.custom-w-35 {
  width: 35%;
}
.w-200 {
  width: 200%;
}
.gap-10 {
  gap: 25px;
}
.verticial-height {
  height: 100vh;
}
.talk-us-input {
  background: transparent !important;
  padding: 30px 20px !important;
  border-radius: 100px !important;
  color: #f1f2f2 !important;
}
.talk-us-input:focus {
  color: #202020 !important;
  background-color: #f1f2f2 !important  ;
  border: 1px solid #f1f2f2 !important;
  outline: none;
  box-shadow: none !important;
}
.talk-us-input::placeholder {
  font-size: 24px !important;

  color: #f1f2f2 !important;
}
.talk-us-input:focus::placeholder {
  /* font-size: 24px !important; */

  color: #202020 !important;
}
.premium-btn {
  border: 0.5px solid #f1f2f2 !important;
  padding: 14px 36px !important;
  font-size: 16px !important;
  margin-top: 1%;
}
.premium-btn svg {
  width: 30px;
  margin-inline-start: 5px;
}
.mobile-view-contact-line {
  display: none;
}
.project-banner {
  padding-top: 15%;
}

.contact-talk-btn {
  border: 1px solid #fff;
  padding: 14px 36px;
  /* height: 50px;
  width: 150px; */
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  font-size: 20px;
  font-family: "Telegraf";
}

.mobile-project-overlay {
  display: flex;
}

.pl-10 {
  padding-left: 10px;
}
/* navbar issues sloving end */

.w-200 {
  width: 200% !important;
}

.talk-btn {
  display: inline-flex;
  padding: 12px 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  border: 1px solid var(--White, #f1f2f2);
  color: var(--White, #f1f2f2);
  background-color: transparent;
  font-size: 20px;
  width: 20%;
  text-decoration: none;
  margin-top: 3%;
  font-family: "Telegraf";
}
.talk-btn svg {
  height: 2vw;
  margin-top: 2%;
  width: 1.5vw;
}
.contact-talk-btn svg {
  height: 2vw;
  margin-top: 2%;
  width: 1.5vw;
}
/* junaid working changing end */
.what-we-section {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
}

.what-we-section .inner-items {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.customhoverjunaid:hover {
  cursor: pointer;
  text-decoration: solid black underline;
}

.customhoverjunaid:active {
  cursor: pointer;
  text-decoration: solid black underline;
}

.customhoverjunaidtwo:hover {
  border-bottom: 1px solid white;
  cursor: pointer;
}

.filter-hover:hover {
  text-decoration: solid white underline;
  cursor: pointer;
}

/* what we do responsive */

.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
  margin-bottom: 5%;
}

.wahtwedovideo {
  object-fit: cover;
  max-height: 80%;
}

.fade-effect {
  transition: opacity 1s ease-in-out, visibility 1s ease-in-out;
}

.fade-in {
  opacity: 1;
  visibility: visible;
}

.fade-out {
  opacity: 0;
  visibility: hidden;
}

.horizontal-crousel .item {
  min-height: 85vh;
  min-width: 60vh;
  cursor: pointer;
}
.horizontal-crousel .content {
  bottom: 5%;
}

.top-10 {
  top: 10%;
}
.what-we-do-heading {
  font-weight: 700;
}
.project-banner {
  height: 100vh;
}
.contant-btn .btn {
  padding: 2% 9%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project .scrolly-img img {
  height: 60vh;
}

.what-slideshow .swiper {
  max-height: 80vh;
}
.what-slideshow .swiper-slide {
  background-color: grey;
  height: auto !important;
  text-align: center;
}
.what-slideshow .swiper-content {
  height: 100% !important;
}

.shape-text {
  color: var(--White, #f1f2f2);
  font-family: Telegraf;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 2vw;
  position: fixed;
  top: 20%;
}

.arrow {
  position: relative;
  top: -10vw;
}
.project .image-row {
  height: 68vh;
}

/* responsive Home */
/* @media screen and (max-width: 1440px) {
  .banner-section {
    height: 120vh;
  }

  .Home-Connecting {
    min-height: auto;
  }

  .connecting-images {
    width: 100%;
  }

  .achieve {
    height: 100vh;
  }

  .project .image-row {
    height: 50vw;
  }

  .project .scrolly-img img {
    height: 44vw;
  }
} */
@media only screen and (min-width: 1441px) and (max-width: 3000px) {
  .banner-section {
    position: relative;
    padding-top: 20vh;
  }
  .wahtwedovideo {
    object-fit: cover;
    max-height: 55%;
  }

  .CookieConsent {
    height: auto;
    font-size: 14px;
  }

  .allProject-img1 img {
    max-height: 65vh;
  }
  .allProject-img6 img {
    max-height: 60vh;
  }
  .what-pictures {
    display: none;
  }
  .arrow {
    position: relative;
    top: -10vw;
  }
  .section-padding {
    padding-top: 6vw;
  }

  .justified-text {
    font-size: 1.666vw;
  }
  .achieve .let-start {
    font-size: 9vw;
  }
  .achieve .something {
    font-size: 10vw;
  }
  .achieve .every {
    font-size: 10vw;
  }
  .big-heading {
    font-size: 8.888vw;
    line-height: 8.3333vw;
  }
  .talent-section h1 {
    font-size: 8.888vw;
  }
  /* .what-we-do-heading{
    
  } */

  .Home-Connecting h1 {
    font-size: 10vw;
    line-height: 10vw;
  }
  .shape-text {
    font-size: 3vw;
  }
  .banner-section h2 {
    font-size: 3vw;
  }
  .talk-btn {
    display: inline-flex;
    padding: 12px 36px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 30px;
    border: 1px solid var(--White, #f1f2f2);
    color: var(--White, #f1f2f2);
    background-color: transparent;
    font-size: 20px;
    width: 21%;
    text-decoration: none;
    margin-top: 3%;
  }
  .inline-thirtytwo {
    width: 100%;
  }
  .inline-thirtythree {
    width: 100%;
  }
  .talent-banner {
    height: auto;
  }
  .project-banner {
    height: auto;
  }
  .what-we-do-info {
    height: auto;
  }
  .project .image-row {
    height: 67vh;
  }
  .inline-six {
    height: auto;
  }
  .custom-padding {
    padding-right: 5vw !important;
    padding-left: 5vw !important;
  }
  .bolding-class {
    font-size: 14vw;
  }
  .stroked-wrapper :hover {
    font-size: 15vw;
  }
  .banner-section .stroked-identity-wrapper {
    font-size: 16vw;
    letter-spacing: 1vw;
  }
  .beside-text {
    font-size: 1.5vw;
  }
  .connecting-images {
    width: 100%;
  }
  .what-we-do {
    height: auto;
  }
  .what-we-do h1 {
    font-size: 8vw;
  }
  .achieve {
    height: auto;
    background: linear-gradient(to bottom, #f1f2f2 50%, #202020 50%);
  }
  .project .scrolly-img img {
    height: 65vh;
  }
  .inline-twenty {
    margin-top: 5px;
  }
  .become-permium {
    padding: 3% 4%;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1430px) {
  .wahtwedovideo {
    object-fit: cover;
    max-height: 70%;
    z-index: 1;
  }

  .CookieConsent {
    height: auto;
    font-size: 14px;
  }

  .allProject-img1 img {
    max-height: 65vh;
  }
  .allProject-img6 img {
    max-height: 60vh;
  }
  .what-pictures {
    display: none;
  }
  .arrow {
    position: relative;
    top: -10vw;
  }
  .section-padding {
    padding-top: 6vw;
  }

  .justified-text {
    font-size: 1.666vw;
  }
  .achieve .let-start {
    font-size: 9vw;
  }
  .achieve .something {
    font-size: 10vw;
  }
  .achieve .every {
    font-size: 10vw;
  }
  .big-heading {
    font-size: 8.888vw;
    line-height: 8.3333vw;
  }
  .talent-section h1 {
    font-size: 8.888vw;
  }
  /* .what-we-do-heading{
    
  } */

  .Home-Connecting h1 {
    font-size: 10vw;
    line-height: 10vw;
  }
  .banner-section h2 {
    font-size: 4vw;
  }
  .talk-btn {
    display: inline-flex;
    padding: 12px 36px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 30px;
    border: 1px solid var(--White, #f1f2f2);
    color: var(--White, #f1f2f2);
    background-color: transparent;
    font-size: 20px;
    width: 20%;
    text-decoration: none;
    margin-top: 3%;
  }
  .inline-thirtytwo {
    width: 100%;
  }
  .inline-thirtythree {
    width: 100%;
  }
  .talent-banner {
    height: auto;
  }
  .project-banner {
    height: auto;
  }
  .what-we-do-info {
    height: auto;
  }
  .project .image-row {
    height: 67vh;
  }
  .inline-six {
    height: auto;
  }
  .custom-padding {
    padding-right: 5vw !important;
    padding-left: 5vw !important;
  }
  .bolding-class {
    font-size: 14vw;
  }
  .stroked-wrapper :hover {
    font-size: 15vw;
  }
  .banner-section .stroked-identity-wrapper {
    font-size: 14vw;
    letter-spacing: 2vw;
  }
  .shape-text {
    font-size: 3vw;
  }
  .banner-section h2 {
    font-size: 3vw;
  }
  .banner-section {
    padding-top: 12vh;
  }
  .beside-text {
    font-size: 1.5vw;
  }
  .connecting-images {
    width: 100%;
  }
  .what-we-do {
    height: auto;
  }
  .what-we-do h1 {
    font-size: 8vw;
  }
  .achieve {
    height: auto;
    background: linear-gradient(to bottom, #f1f2f2 50%, #202020 50%);
  }
  .project .scrolly-img img {
    height: 65vh;
  }
  .inline-twenty {
    margin-top: 5px;
  }
  .become-permium {
    padding: 3% 4%;
  }
  .home-dynamics {
    width: 80%;
    top: 26vh;
  }
  .banner-section hr {
    top: 19%;
  }
}
@media only screen and (max-width: 992px) {
  .rounded-pill {
    border-radius: 15px !important;
    border: 0.5px solid var(--White, #f1f2f2) !important;
  }
  .talk-btn svg {
    height: 22px;
    margin-top: 1%;
    width: 21px;
  }
  .contact-talk-btn svg {
    height: 18px;
    margin-top: 1%;
    width: 22px;
  }

  .premium-btn svg {
    height: 22px;
    width: 22px;
  }
  .talent-slider-container {
    height: 70vh;
  }
  .cookie-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .CookieConsent {
    color: #fff;
    text-align: justify;
    font-family: Telegraf;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: transparent;
    border-radius: 100px;
    border: 1px solid #fff;
    width: 97% !important;
    height: auto;
    display: flex;
    align-items: center !important;
    left: 2% !important;
    padding: 4vw;
    bottom: 3vh !important;
  }
  .cookie-content {
    flex: auto !important;
  }
  .custom-collapse {
    display: block;
    height: 0;
  }
  .wahtwedovideo {
    object-fit: cover;
    max-height: 70%;
  }

  .talent-banner {
    height: auto;
  }
  .line {
    top: 40%;
  }
  .project-banner {
    height: auto;
  }
  .what-we-do-info {
    height: auto;
  }
  .floating-images {
    display: none !important;
  }
  p {
    font-size: 22px;
    line-height: normal;
  }
  .justified-text {
    font-size: 22px;
    line-height: normal;
  }
  .swiper-pagination-bullet {
    width: 12.5px;
    height: 42.5px;
    transform: rotate(-90deg);
    border-radius: 6.25px;
    border: 2px solid #f1f2f2;
    opacity: 1;
    margin-left: 40px;
  }

  .slider-image {
    border-radius: 10px;
  }
  .stroked-wrapper {
    letter-spacing: 1.7vw;
  }

  .stroked-white-wrapper {
    letter-spacing: 1.7vw;
  }

  div[aria-roledescription="carousel"] {
    width: 100%;
  }
  react-slideshow-fadezoom-images-wrap {
    width: 100%;
  }
  .navbar {
    background-color: #202020;
  }
  .navbar-expand-lg .navbar-nav {
    padding-top: 67px;
    gap: 49px;
  }
  .navbar-nav a {
    color: #fff;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0 10px;
    display: inline;
  }
  .menu-member-content {
    position: relative;
    top: 50px;
  }
  .nav-member-btn {
    padding: 10px 80px;
  }
  .v-line-member {
    width: 1px;
    height: 100px;
    border-radius: 50px;
    background-color: #fff;
  }
  .inline-twelve {
    display: block;
    padding: 10px;
    width: 100%;
  }
  .banner-section h1 {
    font-size: 15vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .banner-section h2 {
    padding: 5px 0 25px 0;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  /* .vertical-line-border{
    height:1px;
    width:30px;
    background-color: #fff;
    padding:5px 10px;
    display:inline;
  } */
  .vertical-line {
    position: relative;
  }
  .vertical-line::before {
    position: absolute;
    top: 78%;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #fff;
    left: 60%;
  }
  .Home-Connecting h1 {
    font-size: 11vw;
    line-height: normal;
  }

  .custom-padding {
    padding-left: 1.375rem !important;
    padding-right: 1.375rem !important;
  }
  .mobile-view-span {
    float: right;
    position: relative;
    font-size: 13px;
    padding-right: 30px;
  }
  .block-text {
    display: block;
  }
  .inline-seven {
    margin-left: 0;
  }
  .mobile-pt-10 {
    padding-top: 10px;
  }
  .mobile-brand-color {
    color: #8b4e8e;
  }
  .connecting-images {
    width: 100%;
  }

  .bg-sm-dark {
    background-color: #202020;
  }
  .Home-Connecting {
    height: auto;
    overflow: hidden;
  }
  .talent-section h1 {
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
  }
  .talent-section p {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-right: 3vw;
  }
  .bolding-class {
    font-size: 13vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .stroked-wrapper :hover {
    font-size: 15vw;
    font-weight: bold;
  }
  .what-we-do h1 {
    font-size: 60px;
    line-height: 90px;
  }

  .inline-twentythree {
    height: 38vh;
  }
  .pr-100 {
    padding-right: 100px;
  }
  .what-we-do {
    height: auto;
    padding-top: 2rem;
    overflow: hidden;
  }
  .achieve {
    background: linear-gradient(to bottom, #f1f2f2 50%, #202020 50%);
  }
  .achieve .let-start,
  .achieve .something {
    font-size: 10vw;
    line-height: normal;
  }
  .achieve .every {
    font-size: 10vw;
  }
  .mobile-btn-font {
    font-size: 14px !important;
  }
  .mobile-svg-size {
    width: 36px;
    height: 40px;
    flex-shrink: 0;
  }
  .big-heading {
    font-size: 11vw;
    line-height: 1.1;
  }
  .project-scroll-img.w-50 {
    width: 73% !important;
  }

  .bg-navbar {
    --bs-bg-opacity: 1;
    background-color: #202020 !important;
  }
  .brand-grids {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .contact h1 {
    font-size: 8vw;
  }
  .contact h2 {
    font-size: 5vw;
  }
  .inline-six {
    height: auto;
  }
  .footer-cotact-mobile.inline-eleven {
    width: 100% !important;
    height: 100px;
  }

  .contact p {
    text-align: left;
  }
  .contact > .line {
    display: none !important;
  }

  .mobile-project-content {
    display: block;
    position: absolute;
    bottom: -2%;
    text-align: center;
  }

  .custom-w-project-benner {
    width: 95%;
  }
  .allProject-img1 {
    width: 100%;
  }
  .custom-w-100.w-75 {
    width: 100% !important;
  }
  .custom-project-h1.project-heading {
    width: 100%;
    margin: 0 auto;
  }
  .heading-2 {
    font-size: 36px;
    font-weight: 700;
  }
  .other-work .nav-item a {
    font-size: 24px;
  }
  .other-work.custom-padding.section-padding {
    padding-top: 5rem;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .talk-btn {
    display: inline-flexs;
    width: 95%;
    margin: 0 auto;
    text-align: center;
  }

  .talk-us-input {
    background: transparent !important;
    padding: 20px 20px !important;
    border-radius: 100px !important;
  }
  .talk-us-input:focus {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #202020 !important;
    background-color: #f1f2f2 !important  ;
    border: none !important;
  }
  .talk-us-input::placeholder {
    font-size: 16px !important;

    color: #f1f2f2 !important;
  }
  .premium-btn {
    padding: 9px 10px !important;
    font-size: 16px !important;
    width: 100%;
  }
  .banner-section {
    position: relative;
    padding-top: 5vh;
  }
  .banner-section span.text-body {
    position: relative;
    top: 100px;
  }
  .banner-section p {
    font-size: 2.26666667vw;

    text-align: left !important;
    display: flex !important;
    /* justify-content: ; */
    align-items: center !important;
    width: 60%;
  }
  .arrow-icon-secont-two {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .arrow-icon-secont-two img {
    width: 36px;
    height: 36px;
  }
  .talent-section .talent-name {
    font-size: 16px;
  }
  .inline-nine {
    position: absolute;
    left: 69vw;
    top: 22%;
  }
  .text1 {
    line-height: 40px;
  }
  .what-we-do .outlined {
    font-size: 20px;
  }
  .pl-20 {
    padding-left: 20px !important;
  }
  .pr-40 {
    padding-right: 1vw !important;
  }
  .achieve .coverup {
    border-radius: 20.1111vw 20.1111vw 0px 0px;
  }
  .slider-image {
    display: flex;
    width: 158px;
    height: 90px;
    padding: 16px 26px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
  }
  .stroked-white-wrapper {
    font-size: 14vw;
    padding-top: 0;
    padding-bottom: 7%;
    padding-left: 4.5vw;
  }
  /* .contant-btn .btn {
    font-size: 12px;
    padding: 4.5px 9px;
  } */
  .mobile-view-contact-line {
    display: block;
    height: 100%;
    position: absolute;
    width: 1px;
    background: white;
    right: 0;
  }
  .mobile-project-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2300;
    /* filter: blur(1px); */
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 0.5s ease-in;
  }
  .project-slider {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .project-slider:hover .mobile-project-overlay {
    opacity: 1;
    transition: 0.5s ease-out;
  }
  .project {
    height: auto;
    /* overflow: hidden; */
  }
  .project-banner {
    padding-top: 20%;
  }
  .connecting-p-text {
    position: absolute;
    left: 75vw;
    top: -51%;
    font-size: 14px !important;
    padding-right: 35px !important;
    display: block;
    width: 150px;
  }
  .connecting-talented-text {
    font-size: 64px !important;
    padding-bottom: 25px;
  }
  .talented-block {
    bottom: 8px;
    position: static;
  }
  .font-64 {
    font-size: 65px !important;
  }
  .font-55 {
    font-size: 55px !important;
  }
  .pt-45 {
    padding-top: 45px;
  }
  .inline-twentyfive {
    height: 2px !important;
  }
  .pr-60 {
    padding-right: 10px;
  }
  .pr-11 {
    padding-right: 11px !important;
  }
  .achieve .bg-heading {
    font-size: 20.3333vw;
  }
  .every-line-0 {
    padding: 0 !important;
    line-height: 0 !important;
  }
  .rounded-45 {
    border-radius: 45px !important;
  }
  .h-50px {
    height: 50px !important;
  }
  .mobile-view-project-card.rounded-5 {
    border-radius: 5px !important;
  }
  .mobile-view-project-card-right {
    position: relative;
    left: 22%;
  }
  .custom-mobile-project-scroll {
    overflow-x: scroll !important;
  }
  .text-change {
    font-size: 16px !important;
  }
  .h-img-260px {
    height: 260px !important;
    object-fit: cover !important;
  }
  .h-img-200px {
    height: 260px !important;
    object-fit: cover !important;
  }
  .inline-one {
    width: 100% !important;
    margin: 0 !important;
  }
  .inline-two {
    width: 100% !important;
    margin: 0 !important;
  }
  .f-65 {
    font-size: 65px !important;
  }
  .f-62 {
    font-size: 62px !important;
  }

  .mobile-4-vh.h-100 {
    height: 40vh !important;
  }
  .mobile-font-47px {
    font-size: 47px !important;
  }
  .mobile-font-56px {
    font-size: 80px !important;
  }
  .mobile-what-we-do-premium {
    line-height: normal;
    font-size: 80px;
  }
  .mobile-what-we-do-ourworld {
    display: block !important;
    line-height: normal;
  }
  .accordion-body {
    padding: 0px 0px !important;
  }
  .what-we-do-info {
    padding-top: 20px !important;
  }
  .mobile-talent-arrow {
    position: absolute;
    top: 27%;
    right: 0px;
    width: 36px !important;
  }
  .pr-5px {
    padding-right: 5px !important;
  }
  .talent-slider .item img {
    min-height: 273px !important;
    max-height: 273px !important;
  }
  talent-slider-section .swiper-slide {
    width: 60% !important;
  }
  .inline-twentyone {
    margin-left: 0 !important;
  }
  .contact-top {
    padding-top: 150px;
  }
  .contact-btn-mobile.text-center {
    text-align: left !important;
  }
  .contact-talk-btn {
    height: 45px;
    width: 107px;
  }
  /* mobile view end */

  .navbar-brand {
    width: 130px;
  }
  .achieve {
    height: auto;
  }
  .inline-twentynine {
    height: auto;
  }
  .slider-image {
    display: flex;
    width: 100%;
    height: 19vw;
    padding: 0.625rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid #f1f2f2;
  }
  .slider-image img {
    width: 80%;
  }

  .inline-thirtytwo {
    text-align: justify;
    font-family: Telegraf;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    word-spacing: normal;
    letter-spacing: normal;
    width: 246px;
  }
  .inline-thirtythree {
    text-align: justify;
    font-family: Telegraf;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    word-spacing: normal;
    letter-spacing: normal;
    width: 246px;
  }
  .what-we-do-info .arrow {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    position: relative;
    left: 26%;
    top: -23px;
  }
  .what-we-do-accordin {
    height: auto !important;
  }
  .inline-twentyseven {
    margin-left: auto;
    text-align: justify;
    font-family: Telegraf;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .size-change {
    font-family: Telegraf-UltraBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
  }
  .what-we-do-accordin .accordion-button {
    padding: var(--bs-accordion-btn-padding-y) 0 !important;
  }
  .inline-image-one {
    width: 169px;
    height: 204px;
    flex-shrink: 0;
  }
  .inline-image-two {
    width: 169px;
    height: 204px;
    flex-shrink: 0;
  }
  .inline-image-three {
    width: 169px;
    height: 204px;
    flex-shrink: 0;
  }
  .inline-image-four {
    width: 169px;
    height: 204px;
    flex-shrink: 0;
  }
  .what-we-section {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-project-slider .item {
    min-width: 40vh;
  }
  .top-home-project-slider-10 {
    top: 10%;
  }
  .horizontal-crousel .item {
    min-height: 65vh;
    min-width: 40vh;
  }
  /* .horizontal-crousel .content{
    bottom: 18%;
  } */
  .top-10 {
    top: 20%;
  }
  .what-we-do-heading {
    font-weight: 900;
  }
  .project-banner {
    height: auto;
  }
  .project-mobile-border {
    border-radius: 5px;
  }
  .home-dynamics {
    width: 85%;
    top: 28vh;
  }
  .banner-section .stroked-identity-wrapper {
    font-size: 16vw;
    letter-spacing: 1vw;
  }
  .shape-text {
    top: 22%;
    font-size: 6vw;
  }
  .banner-section p {
    font-size: 3vw;
  }
  .banner-section hr {
    width: 33vw;
    position: absolute;
    top: 13%;
    left: 61%;
  }
  .stroked-wrapper {
    font-size: 15vw;
  }
  .achieve .let-start,
  .achieve .something {
    font-weight: 700;
  }
  .achieve .every {
    font-weight: 700 !important;
  }
}

@media screen and (max-width: 1280px) {
  /* .allProject-img1 {
    width: 50%;

  } */
  .section-padding {
    padding-top: 5rem;
  }
  .project-heading {
    font-size: 18px;
    /* Adjust font size for smaller screens */
  }

  .project-subheading {
    font-size: 14px;
    /* Adjust font size for smaller screens */
  }
}
@media only screen and (max-width: 480px) {
  .inline-nineteen {
    font-size: 15px !important;
  }
  .connecting-text {
    font-size: 50px !important;
  }

  .text1 {
    line-height: 100px;
  }
  .project-banner {
    height: auto;
  }
  .stroked-wrapper {
    font-size: 14vw;
  }
  .banner-section hr {
    width: 33vw;
    position: absolute;
    top: 13%;
    left: 61%;
  }
  .banner-section .stroked-identity-wrapper {
    font-size: 15vw;
    letter-spacing: 1vw;
  }
  .shape-text {
    top: 21vh;
    font-size: 8vw;
  }
  .section-padding {
    padding-top: 5rem;
  }

  .line {
    top: 40%;
  }
  .home-dynamics {
    width: 85%;
    top: 26vh;
  }
  .floating-images {
    display: none !important;
  }

  .contact-talk-btn {
    border: 1px solid #fff;
    padding: 9px 18px;
    /* height: 50px;
    width: 150px; */
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 12px;
    font-size: 16px;
  }
  .talk-btn {
    display: inline-flex;
    padding: 5px 36px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 30px !important;
    border: 1px solid var(--White, #f1f2f2) !important;
    color: var(--White, #f1f2f2) !important;
  }
  p {
    font-size: 16px;
    line-height: normal;
  }
  .justified-text {
    font-size: 16px;
    line-height: normal;
  }
  .swiper-pagination-bullet {
    width: 8.5px !important;
    height: 35.5px !important;
    transform: rotate(-90deg);
    border-radius: 4.25px;
    border: 2px solid #f1f2f2;
    opacity: 1;
    margin-left: 45px !important;
  }

  .slider-image {
    border-radius: 10px;
  }
  .stroked-wrapper {
    letter-spacing: 1vw;
  }

  .stroked-white-wrapper {
    letter-spacing: 1.7vw;
  }

  div[aria-roledescription="carousel"] {
    width: 100%;
  }
  react-slideshow-fadezoom-images-wrap {
    width: 100%;
  }
  .navbar {
    background-color: #202020;
  }
  .navbar-expand-lg .navbar-nav {
    padding-top: 67px;
    gap: 49px;
  }
  .navbar-nav a {
    color: #fff;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0 10px;
  }
  .menu-member-content {
    position: relative;
    top: 50px;
  }
  .nav-member-btn {
    padding: 10px 80px !important;
  }
  .v-line-member {
    width: 1px;
    height: 100px;
    border-radius: 50px;
    background-color: #fff;
  }
  .inline-twelve {
    display: block !important;
    padding: 10px !important;
    width: 100%;
  }
  .banner-section h1 {
    font-size: 17vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .banner-section h2 {
    padding: 5px 0 25px 0;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  /* .vertical-line-border{
    height:1px;
    width:30px;
    background-color: #fff;
    padding:5px 10px;
    display:inline;
  } */
  .vertical-line {
    position: relative;
  }
  .vertical-line::before {
    position: absolute;
    top: 78%;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #fff;
    left: 60%;
  }
  .Home-Connecting h1 {
    font-size: 54px;

    line-height: 50px;
  }

  .custom-padding {
    padding-left: 1.375rem !important;
    padding-right: 1.375rem !important;
  }
  .mobile-view-span {
    float: right;
    position: relative;
    font-size: 13px !important;
    padding-right: 30px !important;
  }
  .block-text {
    display: block !important;
  }
  .connecting-text-3 {
    font-size: 55px !important;
  }
  .inline-seven {
    margin-left: 0 !important;
  }
  .mobile-pt-10 {
    padding-top: 10px;
  }
  .mobile-brand-color {
    color: #8b4e8e;
  }
  .connecting-images {
    width: 100%;
  }

  .bg-sm-dark {
    background-color: #202020 !important;
  }
  .Home-Connecting {
    height: auto !important;
    overflow: hidden;
  }
  .talent-section h1 {
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
  }
  .talent-section p {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-right: 95px;
  }
  .bolding-class {
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .stroked-wrapper :hover {
    font-size: 14vw !important;
    font-weight: bold !important;
  }
  .what-we-do h1 {
    font-size: 60px;
    line-height: 90px !important;
  }

  .inline-twentythree {
    height: 38vh !important;
  }
  .pr-100 {
    padding-right: 100px;
  }
  .what-we-do {
    height: auto;
    padding-top: 2rem;
    overflow: hidden;
  }
  .achieve .let-start,
  .achieve .something {
    font-size: 77px;
    line-height: normal;
    font-weight: 700;
    margin-top: 2vw;
  }
  .achieve .every {
    font-size: 77px;
    font-weight: 700 !important;
  }
  .mobile-btn-font {
    font-size: 14px !important;
  }
  .mobile-svg-size {
    width: 36px;
    height: 40px;
    flex-shrink: 0;
  }
  .big-heading {
    font-size: 64px;
    line-height: 1.1;
  }
  .project-scroll-img.w-50 {
    width: 73% !important;
  }

  .bg-navbar {
    --bs-bg-opacity: 1;
    background-color: #202020 !important;
  }
  .brand-grids {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .contact h1 {
    font-size: 9vw;
  }
  .contact h2 {
    font-size: 7vw;
  }
  .inline-six {
    height: auto;
  }
  .footer-cotact-mobile.inline-eleven {
    width: 75% !important;
    height: 50px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .contact-end-text {
    font-size: 12px !important;
  }

  .contact p {
    text-align: left;
  }
  .contact > .line {
    display: none !important;
  }

  .mobile-project-content {
    display: block;
    position: absolute;
    bottom: -2%;
    text-align: center;
  }
  .custom-w-project-benner {
    width: 95%;
  }
  .allProject-img1 {
    width: 100%;
  }
  .custom-w-100.w-75 {
    width: 100% !important;
  }
  .custom-project-h1.project-heading {
    width: 100%;
    margin: 0 auto;
  }
  .heading-2 {
    font-size: 36px;
    font-weight: 700;
  }
  .other-work .nav-item a {
    font-size: 24px;
  }
  .other-work.custom-padding.section-padding {
    padding-top: 5rem;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    height: auto;
    padding-bottom: 5rem;
  }

  .talk-btn {
    width: 95%;
    margin: 0 auto;
    text-align: center;
  }

  .talk-us-input {
    background: transparent !important;
    padding: 20px 20px !important;
    border-radius: 100px !important;
  }
  .talk-us-input:focus {
    color: #202020 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .talk-us-input::placeholder {
    font-size: 16px !important;

    color: #f1f2f2 !important;
  }
  .premium-btn {
    padding: 9px 10px !important;
    font-size: 16px !important;
    width: 100%;
  }
  .banner-section span.text-banner {
    position: relative;
    top: 100px;
  }
  .banner-section p {
    font-size: 4.26666667vw;
    /* position: relative; */
    /* top: 100px; */
    text-align: justify !important;
    display: flex !important;
    /* justify-content: ; */
    align-items: center !important;
    width: 50%;
  }
  .banner-section hr {
    top: 9%;
    left: 55%;
    width: 23vw;
  }
  .arrow-icon-secont-two {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .arrow-icon-secont-two img {
    width: 36px;
    height: 36px;
  }
  .talent-section .talent-name {
    font-size: 16px;
  }
  .inline-nine {
    margin-top: 22px !important;
    position: relative;
    right: 35px;
  }
  .what-we-do .outlined {
    font-size: 20px;
  }
  .pl-20 {
    padding-left: 20px !important;
  }
  .pr-40 {
    padding-right: 40px !important;
  }
  .achieve .coverup {
    /* position: relative; */
    border-radius: 20.1111vw 20.1111vw 0px 0px;
  }
  .slider-image {
    display: flex;
    width: 158px;
    height: 90px;
    padding: 16px 26px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
  }
  .stroked-white-wrapper {
    font-size: 14vw;
    padding-top: 0;
    padding-bottom: 7%;
    padding-left: 4.5vw;
  }
  .contant-btn .btn {
    font-size: 12px;
    padding: 2% 9%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobile-view-contact-line {
    display: block;
    height: 100%;
    position: absolute;
    width: 1px;
    background: white;
    right: 0;
  }
  .mobile-project-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2300;
    /* filter: blur(1px); */
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 0.5s ease-in;
  }
  .project-slider {
    position: relative;
  }
  .project-slider:hover .mobile-project-overlay {
    opacity: 1;
    transition: 0.5s ease-out;
  }
  .project {
    height: auto;
    /* overflow: hidden; */
  }
  .project-banner {
    padding-top: 20%;
  }
  .connecting-p-text {
    position: absolute;
    left: 60%;
    top: 12px;
    font-size: 14px !important;
    padding-right: 35px !important;
    display: block;
    width: 150px;
  }
  .connecting-talented-text {
    font-size: 64px !important;
    padding-bottom: 0;
  }
  .talented-block {
    bottom: 20px;
    position: relative;
  }
  .font-64 {
    font-size: 65px !important;
  }
  .font-55 {
    font-size: 65px !important;
  }
  .pt-45 {
    padding-top: 45px;
  }
  .inline-twentyfive {
    height: 2px !important;
  }
  .pr-60 {
    padding-right: 60px !important;
  }
  .pr-11 {
    padding-right: 11px !important;
  }
  .achieve .bg-heading {
    font-size: 20.3333vw;
  }
  .every-line-0 {
    padding: 0 !important;
    line-height: 0 !important;
  }
  .rounded-45 {
    border-radius: 45px !important;
  }
  .h-50px {
    height: 50px !important;
  }
  .mobile-view-project-card.rounded-5 {
    border-radius: 5px !important;
  }
  .mobile-view-project-card-right {
    position: relative;
    left: 22%;
  }
  .custom-mobile-project-scroll {
    overflow-x: scroll !important;
  }
  .text-change {
    font-size: 16px !important;
  }
  .h-img-260px {
    height: 260px !important;
    object-fit: cover !important;
  }
  .h-img-200px {
    height: 260px !important;
    object-fit: cover !important;
  }
  .inline-one {
    width: 100% !important;
    margin: 0 !important;
  }
  .inline-two {
    width: 100% !important;
    margin: 0 !important;
  }
  .f-65 {
    font-size: 65px !important;
  }
  .f-62 {
    font-size: 62px !important;
  }

  .mobile-4-vh.h-100 {
    height: 40vh !important;
  }
  .mobile-font-47px {
    font-size: 47px !important;
  }
  .mobile-font-56px {
    font-size: 56px !important;
  }
  .mobile-what-we-do-premium {
    line-height: 65px;
    font-size: 64px;
  }
  .mobile-what-we-do-ourworld {
    display: block !important;
    padding-top: 20px !important;
    line-height: 40px;
  }
  .what-we-do-info {
    padding-top: 20px !important;
  }
  .mobile-talent-arrow {
    position: absolute;
    top: -21px;
    right: 0px;
    width: 36px !important;
  }
  .pr-5px {
    padding-right: 5px !important;
  }
  .talent-slider .item img {
    min-height: 273px !important;
    max-height: 273px !important;
  }
  talent-slider-section .swiper-slide {
    width: 60% !important;
  }
  .inline-twentyone {
    margin-left: 0 !important;
  }
  .contact-top {
    padding-top: 150px;
  }
  .contact-btn-mobile.text-center {
    text-align: left !important;
  }
  .contact-talk-btn {
    height: 45px;
    width: 107px;
  }
  /* mobile view end */

  .navbar-brand {
    width: 130px;
  }
  .achieve {
    height: auto;
  }
  .inline-twentynine {
    height: auto;
  }
  .slider-image {
    display: flex;
    width: 100%;
    height: 24vw;
    padding: 0.625rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid #f1f2f2;
  }
  .slider-image img {
    width: 70%;
  }

  .inline-thirtytwo {
    text-align: justify;
    font-family: Telegraf;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    word-spacing: normal;
    letter-spacing: normal;
    width: 246px;
  }
  .inline-thirtythree {
    text-align: justify;
    font-family: Telegraf;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    word-spacing: normal;
    letter-spacing: normal;
    width: 246px;
  }
  .what-we-do-info .arrow {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    position: relative;
    left: 26%;
    top: -23px;
  }
  .what-we-do-accordin {
    height: auto !important;
  }
  .inline-twentyseven {
    margin-left: auto;
    text-align: justify;
    font-family: Telegraf;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .size-change {
    font-family: Telegraf-UltraBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
  }
  .what-we-do-accordin .accordion-button {
    padding: var(--bs-accordion-btn-padding-y) 0 !important;
  }
  .inline-image-one {
    width: 169px;
    height: 204px;
    flex-shrink: 0;
  }
  .inline-image-two {
    width: 169px;
    height: 204px;
    flex-shrink: 0;
  }
  .inline-image-three {
    width: 169px;
    height: 204px;
    flex-shrink: 0;
  }
  .inline-image-four {
    width: 169px;
    height: 204px;
    flex-shrink: 0;
  }
  .what-we-section {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home-project-slider .item {
    min-width: 40vh;
  }
  .top-home-project-slider-10 {
    top: 10%;
  }
  .horizontal-crousel .item {
    min-height: 65vh;
    min-width: 40vh;
  }
  .top-10 {
    top: 20%;
  }
  .what-we-do-heading {
    font-weight: 900;
  }
  /* .project-banner {
    height: 70vh;
  } */
  .project-mobile-border {
    border-radius: 5px;
  }
  .cookie-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .CookieConsent {
    color: #fff;
    text-align: justify;
    font-family: Telegraf;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: transparent;
    border-radius: 100px;
    border: 1px solid #fff;
    width: 97% !important;
    height: 32.93vw;
    display: flex;
    align-items: center !important;
    left: 2% !important;
    padding: 6vw;
    bottom: 3vh !important;
  }
  .cookie-content {
    flex: auto !important;
  }
  #rcc-confirm-button {
    padding: 1vw 5vw !important;
  }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    width: 87% !important;
  }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -10px !important;
  }
  .swiper-wrapper {
    margin-bottom: 18%;
  }
  .inline-thirty {
    letter-spacing: normal;
    line-height: normal;
  }
  .accordion-body {
    padding: 0px 0px !important;
  }
  .wahtwedovideo {
    object-fit: cover;
    max-height: 100%;
    position: static;
    z-index: 1;
  }
  .touch-scroll {
    height: 4rem;
    -webkit-overflow-scrolling: touch;
  }
  .talent-slider-container {
    height: 70vh;
  }
}
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .connecting-p-text {
    left: 57vw;
    top: 10px;
  }
}
@media only screen and (max-width: 420px) {
  .stroked-wrapper {
    font-size: 13vw;
  }
  .banner-section hr {
    width: 33vw;
    position: absolute;
    top: 13%;
    left: 61%;
  }

  .shape-text {
    top: 22vh;
    font-size: 7vw;
  }
  .section-padding {
    padding-top: 5rem;
  }

  .line {
    top: 40%;
  }
  .home-dynamics {
    width: 85%;
    top: 26vh;
  }
  .floating-images {
    display: none !important;
  }

  .banner-section h1 {
    font-size: 14vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .Home-Connecting h1 {
    font-size: 40px;

    line-height: 50px;
  }

  .talent-section h1 {
    font-size: 14vw;
  }

  .bolding-class {
    font-size: 13vw;
  }
  .stroked-wrapper :hover {
    font-size: 14vw !important;
    font-weight: bold !important;
  }
  .what-we-do h1 {
    font-size: 14vw;
    line-height: 90px !important;
  }

  .pr-100 {
    padding-right: 80px;
  }

  .achieve .let-start,
  .achieve .something {
    font-size: 16vw;
  }
  .achieve .every {
    font-size: 14vw;
  }

  .big-heading {
    font-size: 13vw;
    line-height: 1.1;
  }

  .pl-20 {
    padding-left: 35px !important;
  }
  .pr-40 {
    padding-right: 20px !important;
  }
  .font-64 {
    font-size: 16vw !important;
  }
  .font-55 {
    font-size: 45px !important;
  }

  .connecting-text-4 {
    font-size: 50px !important;
  }
  .connecting-text-2 {
    font-size: 64px !important;
  }
  .pt-45 {
    padding-top: 35px;
  }

  .pr-60 {
    padding-right: 50px !important;
  }
  .pr-11 {
    padding-right: 8px !important;
  }
  .navbar-expand-lg .navbar-nav {
    gap: 3vh;
    padding-top: 7vh;
  }
}

@media only screen and (max-width: 375px) {
  .navbar-expand-lg .navbar-nav {
    gap: 5vh;
    padding-top: 10vh;
  }
  .navbar-nav a {
    font-size: 5vh;
  }
  .custom-padding {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .premium-btn {
    padding: 7px 8px !important;
    font-size: 13px !important;
    width: 96%;
  }
  .CookieConsent {
    font-size: 11px;
    width: 90% !important;
    left: 6% !important;
    padding: 5vw 2vw 5vw 2vw;
    bottom: 10vh !important;
  }
  .Home-Connecting h1 {
    font-size: 12vw;
    line-height: 12vw;
  }
  .connecting-talented-text {
    font-size: 16vw !important;
    padding-bottom: 0;
  }
  .connecting-p-text {
    left: 55%;
    top: 12%;
    font-size: 12px !important;
    padding-right: 0px !important;
    width: 100px;
  }
  .font-55 {
    font-size: 13vw !important;
  }
  .talent-section h1 {
    font-size: 13vw;
  }
  .what-we-do h1 {
    font-size: 14vw;
  }
  .pr-40 {
    padding-right: 2vw !important;
  }
  .pl-20 {
    padding-left: 10vw !important;
  }
  .what-we-do .outlined {
    margin-bottom: 10px;
  }
  .scroll-down-btn {
    font-size: 12px !important;
  }
  .mobile-svg-size {
    width: 28px;
  }
  .big-heading {
    font-size: 14vw;
  }
  .inline-nineteen {
    font-size: 10px !important;
  }

  .premium-btn svg {
    width: 15px;
    height: 15px;
  }
  .inline-twentythree {
    height: auto !important;
  }
  .what-we-do .outlined {
    font-size: 16px;
  }
}

.heading-animation {
  animation: text-clip 3s 0s cubic-bezier(0.42, 0, 0.58, 1) both;
}

@keyframes text-clip {
  from {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
  to {
    clip-path: polygon(0 0, 100% 0, 100% 300%, 0 300%);
  }
}

.what-we-do-video-container video {
  object-fit: fill;
}
@media screen and (max-width: 828px) {
  .g-0 {
    --bs-gutter-y: -4px;
    --bs-gutter-x: 0;
  }
}
