.video-main-heading {
  padding-top: 90px;
  padding-bottom: 60px;
  color: var(--Black, #202020);
  font-family: Antonio;
  font-size: 120px;
  font-style: normal;
  font-weight: 700;
  line-height: 135px;
  text-transform: uppercase;
}

.video-heading-line {
  width: 55%;
  height: 1.872px;
  background: #aebfbf;
}

.video-description {
  color: var(--Black, #202020);
  text-align: justify;
  font-family: Telegraf;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.video-description-video-wrapper {
  width: 33.8055555556vw;
  height: 33.3194444444vw;
  flex-shrink: 0;
  border-radius: 30px;
  background: var(--Cobalt, #aebfbf);
  margin-top: 8vh;
  padding-top: 7%;
}

.video-description-video-wrapper div {
  width: 28.25vw;
  height: 40.9583333333vw;
  flex-shrink: 0;
  border-radius: 15px;
  background: var(--Grey, #888989);
}

.video-text {
  color: var(--Black, #202020);
  font-family: Telegraf;
  font-size: 72px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.video-two-wrapper {
  width: 36.805555556vw;
  height: 24.652777778vw;
  flex-shrink: 0;
  border-radius: 20px;
  background: #aebfbf;
  display: flex;
  align-items: flex-end;
}
.video-two-wrapper .item {
  width: 29.236111111vw;
  height: 19.513888889vw;
  flex-shrink: 0;
  border-radius: 0px 10px;
  background: transparent;
}
.video-two-wrapper-2 {
  width: 36.805555556vw;
  height: 24.652777778vw;
  flex-shrink: 0;
  padding: 3vw;
  border-radius: 20px;
  background: #aebfbf;
  display: flex;
  align-items: center;
}
.video-two-wrapper-2 .item {
  width:36.236111111vw;
  height: 19.513888889vw;
  flex-shrink: 0;
  border-radius: 0px 10px;
  background: transparent;
}

@media (min-width: 1441px) {
  .video-main-heading {
    font-size: 150px;
    line-height: 162px;
  }

  .video-description {
    font-size: 30px;
    line-height: 36px;
  }

  .video-description-video-wrapper  {
    width: 30.8055555556vw;
    height: 30.3194444444vw;

    margin-top: 4vh;
    padding-top: 7%;
  }

  .video-description-video-wrapper  div {
    width: 24.25vw;
    height: 35.9583333333vw;
  }
  .video-two-wrapper-2 {
    width: 36.805555556vw;
    height: 24.652777778vw;
    flex-shrink: 0;
    padding: 3vw;
    border-radius: 20px;
    background: #aebfbf;
    display: flex;
    align-items: center;
  }
  .video-two-wrapper-2 .item {
    width:36.236111111vw;
    height: 19.513888889vw;
    flex-shrink: 0;
    border-radius: 0px 10px;
    background: transparent;
  }
}
@media (max-width: 1300px) {
  .video-description-video-wrapper {
    width: 30.8055555556vw;
    height: 30.3194444444vw;

    margin-top: 0;
    padding-top: 7%;
  }

  .video-description-video-wrapper div {
    width: 24.25vw;
    height: 35.9583333333vw;
  }
  .video-two-wrapper-2 {
    width: 36.805555556vw;
    height: 24.652777778vw;
    flex-shrink: 0;
    padding: 3vw;
    border-radius: 20px;
    background: #aebfbf;
    display: flex;
    align-items: center;
  }
  .video-two-wrapper-2 .item {
    width:36.236111111vw;
    height: 19.513888889vw;
    flex-shrink: 0;
    border-radius: 0px 10px;
    background: transparent;
  }

}


@media (max-width: 1024px) {
  .video-main-heading {
    font-size: 135px;
    line-height: 156px;
  }

  .video-description {
    font-size: 24px;
    line-height: 30px;
  }

  .video-description-video-wrapper  {
    margin-top: 16vw;
  }
}

@media (max-width: 768px) {
  .video-main-heading {
    font-size: 72px;
    line-height: 86px;
  }

  .video-heading-line {
    width: 40%;
    height: 1.872px;
    background: #aebfbf;
  }

  .video-description {
    font-size: 16px;
    line-height: 20px;
  }

  .video-description-video-wrapper  {
    width: 300px;
    height: 300px;
    margin-top: 18vw;
  }

  .video-description-video-wrapper  div {
    width: 240px;
    height: 360px;
  }
  .video-two-wrapper-2 {
    width: 36.805555556vw;
    height: 24.652777778vw;
    flex-shrink: 0;
    padding: 3vw;
    border-radius: 20px;
    background: #aebfbf;
    display: flex;
    align-items: center;
  }
  .video-two-wrapper-2 .item {
    width:36.236111111vw;
    height: 19.513888889vw;
    flex-shrink: 0;
    border-radius: 0px 10px;
    background: transparent;
  }
}

@media (max-width: 600px) {
  .video-two-wrapper {
    width: 89.333333333vw;
    height: 50.933333333vw;
    border-radius: 10px;
    align-items: flex-start;
  }
  .video-two-wrapper .item {
    width: 65.6vw;
    height: 40.266666667vw;
    flex-shrink: 0;
    border-radius: 5px;
    background: transparent;
  }
  .video-main-heading {
    font-size: 11.9333333333vw;
    line-height: normal;
    padding-top: 30px;
    padding-bottom: 15px;
  }
  .video-heading-line {
    width: 90%;
  }

  .video-description {
    font-size: 16px;
    line-height: 20px;
  }
  .video-two-wrapper-2 {
    width: 66.805556vw;
    height: 56.652778vw;
    flex-shrink: 0;
    padding: 5vw;
    border-radius: 20px;
    background: #aebfbf;
    display: flex;
    align-items: center;
}
.video-two-wrapper-2 .item {
  width: 78.236111vw;
  height: 45.513889vw;
  flex-shrink: 0;
  border-radius: 0px 10px;
  background: transparent;
}
  .video-description-video-wrapper  {
    width: 63.6vw; /* 246px / 375px * 100 */
    height: 62.8vw; /* 243px / 375px * 100 */
    border-radius: 2.6666666667vw; /* 10px / 375px * 100 */
    margin-top: 2.6666666667vw; /* 10px / 375px * 100 */
  }

  .video-description-video-wrapper  div {
    border-radius: 2.6666666667vw; /* 10px / 375px * 100 */
    width: 53.4666666667vw; /* 208px / 375px * 100 */
    height: 76.1333333333vw; /* 293px / 375px * 100 */
  }
}
