.three-picture-section-layout-2-wrapper1 {
  width: 90.277777778vw;
  height: 30vw;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 20px 20px 20px 0px;
  background: var(--White, #F1F2F2);
}

.three-picture-section-layout-2-wrapper1 .item1 {
  width: 65.194444444vw;
  height: 23.055555556vw;
  flex-shrink: 0;
  border-radius: 8px;
  background: transparent;
}

.three-picture-section-layout-2-wrapper3 .item1 {
    position: relative;
    top: 5rem;
    width: 21.527777778vw;
  height: 26.666666667vw;
  flex-shrink: 0;
  background: #6e6e6e;
}
.three-picture-section-layout-2-wrapper2 {
  width: 62.777777778vw;
  height: 28.333333333vw;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 20px 0px 20px 20px;
background: var(--White, #F1F2F2);
}

.three-picture-section-layout-2-wrapper2 .item1 {
  width: 67.361111111vw;
  height: 22.777777778vw;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Grey, #888989);
}
.three-picture-section-layout-2-text{

  color: var(--Black, #202020);
  text-align: justify;
  font-family: Telegraf;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 125% */
}

@media screen and (max-width: 767px) {
    .three-picture-section-layout-2-wrapper1 {
        width: 88.8vw;
        height: 53.333333333vw;
    
      }
      
      .three-picture-section-layout-2-wrapper1 .item1 {
        width: 78.133333333vw;
        height: 42.666666667vw;
   
      }
      
      .three-picture-section-layout-2-wrapper3 .item1 {
          position: relative;
          top: 30vw;
          width: 70.666666667vw;
          height: 66.933333333vw;
        border-radius: 10px;
        background: #6e6e6e;
      }
      .three-picture-section-layout-2-wrapper2 {
          margin-top: 3vw;
           
      }
      
      .three-picture-section-layout-2-wrapper2 .item1 {
        width: 65.6vw;
        height: 31.733333333vw;
        flex-shrink: 0;
        border-radius: 8px;
     background:transparent;
        box-shadow: 25vw  80px 0px 20px #fff;
      }
      .style-info-section-wrapper1 {
        width: 89.333333333vw;
        height: 54.133333333vw;
        flex-shrink: 0;
        background: #F1F2F2;
        border-radius: 0.555555556vw;
        padding: 3vw;
      }
      
}