.two-video-wrapper1 {
    width: 36.8055555556vw; 
    height: 50.8333333333vw; 
    flex-shrink: 0;
    border-radius:15px;
    background:#F1F2F2;
    margin-top: 5vw;

}

.two-video-wrapper1 div {
    width: 31.25vw;
    height: 53.6111111111vw ;
    flex-shrink: 0;
    border-radius: 8px;
 background:transparent;
    position: relative;
    bottom: 10.5%;
}

.video-text {
    color: var(--Black, #202020);
    font-family: Telegraf;
    font-size: 72px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.two-video-wrapper2 {
    width: 44.4444444444vw; 
    height: 28.1944444444vw; 
    flex-shrink: 0;
    border-radius:15px;
    background:#F1F2F2;
    
    

}

.two-video-wrapper2 div {
    width: 31.25vw;
    height: 53.6111111111vw ;
    flex-shrink: 0;
    border-radius: 8px;
 background:transparent;
   
}
@media (min-width: 1441px) {
   
    .two-video-wrapper1 {
        height: 76vh;
  
        margin-top: 5vh;
    }
    
    .two-video-wrapper1 div {
        height: 100%;
      
    }
    
    .two-video-wrapper2 div {
        height: 81vh;
      
    }
}

@media (max-width: 1300px) {
   
    .two-video-wrapper1 {
        height: 76vh;
  
        margin-top: 5vh;
    }
    
    .two-video-wrapper1 div {
        height: 100%;
      
    }
    
    .two-video-wrapper2 div {
        height: 81vh;
      
    }

}

@media (max-width:768px) {
    .two-video-wrapper1 {
        width: 88.8vw; 
        height:  97.6vw; 
    
    }
    
    .two-video-wrapper1 div {
        width: 60vw;
        height: 102.933333333vw;
    }
 
    .two-video-wrapper2 {
        width: 85.3333333333vw; 
        height:54.1333333333vw ; 
        margin-top: 10vw;
    }
    
    .two-video-wrapper2 div {
        width: 60vw ;
        height: 64.8vw ;
       
    }
}