.resume-heading {
  color: #f1f2f2;
  font-family: Telegraf;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.resume-description {
  color: #f1f2f2;
  text-align: justify;
  font-family: Telegraf;
  font-size: 1.6666666667vw;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.resume-line {
  width: 2px;
  background: white;
  height: 100%;
}
.big-video {
  width: 36.805555556vw;
  height: 46.180555556vw;
  flex-shrink: 0;
  border-radius: 20px;
  background: var(--Cobalt, #aebfbf);
}
.beside-vertical-text {
  color: #aebfbf;
  font-family: Telegraf;
  font-size: 2.777777778vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  transform: rotate(90deg);
  top: 57%;
  left: 56%;
  width: 100%;
}
.resume-video-2 {
  width: 36.805555556vw;
  height: 27.777777778vw;
  flex-shrink: 0;
  border-radius: 20px;
  background: #aebfbf;
}
.resume-item-video{
  height: 92vh;

}
@media screen and (max-width: 767px) {
  .resume-heading {
    font-size: 5.333333333vw;
    line-height: normal;
  }

  .resume-description {
    font-size: 4.2666666667vw;

    line-height: normal;
  }

  .resume-line {
    position: absolute;
    left: 0;
    width: 90%;
    background: white;
    height: 2px;
    margin-top: 5vw;
    margin-bottom: 5vw;
  }
  .big-video {
    width: 70.666666667vw;
    height: 88.533333333vw;
    border-radius: 10px;
    margin-top: 12vw;
  }
  .beside-vertical-text {
    font-family: Telegraf;
    font-size: 5.333333333vw;
    text-align: center;
    position: static;
    transform: none;
    margin-top: 2vw;
  }
  .resume-video-2 {
    width: 65.6vw;
    height: 65.6vw;

  }
}
