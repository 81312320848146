.our-task-heading {
  color: #f1f2f2;
  font-family: Antonio;
  font-size: 8.8888888889vw;
  font-style: normal;
  font-weight: 700;
  line-height: 10.1388888889vw;
}

.our-task-description {
  color: #f1f2f2;
  text-align: justify;
  font-family: Telegraf;
  font-size: 1.6666666667vw;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.ourtask-text p {
  color: #f1f2f2;
  font-family: Telegraf;
  font-size: 3.3333333333vw;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  padding: 0.6944444444vw 4.1666666667vw 0.6944444444vw 0;
}

.task-line {
    width:2px;
    background: white;
   height: 100%;
}



@media screen and (max-width: 767px) {
    .our-task-heading {
        font-size: 14.9333333333vw;
        line-height: normal;
      }
      
      .our-task-description {
     
        font-size: 4.2666666667vw;
     
        line-height: normal;
      }
      
      .ourtask-text p {
        color: #f1f2f2;
        font-family: Telegraf;
        font-size: 6.4vw;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-transform: uppercase;
        padding: 3.4vw 0;
      }
      
      .task-line {
        position: absolute;
        left: 0;
          width:90%;
          background: white;
         height: 2px;
         margin-top: 5vw;
         margin-bottom: 5vw;

      }
      
    
}