.style-section-layout2{
  padding-bottom: 3vw;
}
.style {
  position: absolute;
  font-family: Telegraf-UltraBold;
  top: -19%;
  right: 0;
  font-size: 8rem;
  letter-spacing: 0.1em;
  color: transparent;
  -webkit-text-stroke: 3px #202020;
  z-index: 999;
}
.style-info-section-layout2-wrapper1 {
  width: 36.805555556vw;
  height: 50.833333333vw;
  flex-shrink: 0;
  background: var(--Cobalt, #aebfbf);
  border-radius: 0.555555556vw;
  padding: 3vw;
}
.style-info-section-layout2-wrapper1 .item1 {
  width: 31.25vw;
  height: 37.083333333vw;
  flex-shrink: 0;
  border-radius: 0.555555556vw;
  background: var(--Grey, #888989);
}

.style-info-section-layout2-wrapper1 .item2 {
  width: 18.75vw;
  height: 23.263888889vw;
  flex-shrink: 0;
  border-radius: 0.555555556vw 0px 0.555555556vw 0.555555556vw;
  background: #6f6f6f;
  position: absolute;
  right: 8.5%;
  bottom: 6%;
}

.style-info-section-layout2-wrapper2 {
  width: 52.083333333vw;
  height: 27.986111111vw;
  flex-shrink: 0;
  background: var(--Cobalt, #aebfbf);
  border-radius: 0.555555556vw;
  padding: 3vw;
}
.style-info-section-layout2-wrapper2 .item1 {
  width: 100%;
  height: 22.430555556vw;
  flex-shrink: 0;
  border-radius: 0.555555556vw;
  background: #6f6f6f;
}
.style-info-section-layout2-wrapper2 .item2 {
  width: 26.388888889vw;
  height: 36.597222222vw;
  flex-shrink: 0;
  border-radius: 0.555555556vw;
  background: var(--Grey, #888989);
  position: absolute;
  top: 50%;
  right: 0;
}
.image-text {
  color: var(--Black, #202020);
  text-align: justify;
  font-family: Telegraf;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 125% */
}
@media screen and (max-width: 992px) {
  .style-section-layout2-layout-1 {
    padding-bottom: 3vw;
  }
  .style {
    position: absolute;
    font-family: Telegraf-UltraBold;
    top: -12%;
    left: 0;
    font-size: 20vw;
    letter-spacing: 0.1em;
    color: transparent;
    -webkit-text-stroke: 3px #202020;
    z-index: 999;
  }
  .style-info-section-layout2-wrapper1 {
    width: 89.333333333vw;
    height: 54.133333333vw;
    flex-shrink: 0;
    background: var(--Cobalt, #aebfbf);
    border-radius: 0.555555556vw;
    padding: 3vw;
  }
  .style-info-section-layout2-wrapper1 .item1 {
    width:100%;
    height:100%;
    flex-shrink: 0;
    border-radius: 0.555555556vw;
 background:transparent;
  }

   .section2.item2 {
    width: 89.333333333vw;
    height: 99.733333333vw;
    flex-shrink: 0;
    border-radius: 0.555555556vw 0px 0.555555556vw 0.555555556vw;
    background: #6f6f6f;

    margin-top: 5vw;
  }

  .style-info-section-layout2-wrapper2 {
    width: 89.333333333vw;
    height: 97.6vw;
    flex-shrink: 0;
    background: var(--Cobalt, #aebfbf);
    border-radius: 0.555555556vw;
    padding: 6vw;
  }
  .style-info-section-layout2-wrapper2 .item1 {
    width: 78.133333333vw;
    height: 70.933333333vw;
    flex-shrink: 0;
    border-radius: 0.555555556vw;
    background: #6f6f6f;
  }
  .style-info-section-layout2-wrapper2 .item2 {
    width: 48.8vw;
    height: 60.533333333vw;
    flex-shrink: 0;
    border-radius: 0.555555556vw;
 background:transparent;
    position: absolute;
    top: 30%;
    right: 0;
  }
  .image-text {
    color: var(--Black, #202020);
    text-align: justify;
    font-family: Telegraf;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
@media screen and (max-width: 480px) {
  .style {
    font-size: 16.133333vw;
  }
}
