.project-main-heading {
    padding-top: 90px;
    padding-bottom: 60px;
    color: var(--Black, #202020);
    font-family: Antonio;
    font-size: 120px;
    font-style: normal;
    font-weight: 700;
    line-height: 135px;
    text-transform: uppercase;
}

.project-heading-line {
    width: 55%;
    height: 1.872px;
    background: #aebfbf;
}

.project-description {
    color: var(--Black, #202020);
    text-align: justify;
    font-family: Telegraf;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
}

.project-description-video-wrapper {
    width: 33.8055555556vw; 
    height: 33.3194444444vw; 
    flex-shrink: 0;
    border-radius: 30px;
    background: var(--Cobalt, #aebfbf);
    margin-top: 8vh;
    padding-top: 7%;
}

.project-description-video-wrapper div {
    width: 28.25vw;
    height: 40.9583333333vw ;
    flex-shrink: 0;
    border-radius: 15px;
}

.video-text {
    color: var(--Black, #202020);
    font-family: Telegraf;
    font-size: 72px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media (min-width: 1441px) {
   
    .project-description-video-wrapper {
        width: 30.8055555556vw; 
        height: 30.3194444444vw; 
    
        margin-top: 0;
        padding-top: 7%;
    }
    
    .project-description-video-wrapper div {
        width: 24.25vw;
        height: 35.9583333333vw ;

    }

}

@media (max-width: 1300px) {
   
    .project-description-video-wrapper {
        width: 30.8055555556vw; 
        height: 30.3194444444vw; 
    
        margin-top: 0;
        padding-top: 7%;
    }
    
    .project-description-video-wrapper div {
        width: 24.25vw;
        height: 35.9583333333vw ;

    }

}

  
 

@media (max-width: 768px) {
    .project-main-heading {
      font-size: 72px;
      line-height: 86px;
    }
    
    .project-heading-line {
        width: 40%;
        height: 1.872px;
        background: #aebfbf;
    }
    
    .project-description {
      font-size: 16px;
      line-height: 20px;
    }

    .project-description-video-wrapper {
      width: 300px;
      height: 300px;
      margin-top: 18vw;
    }

    .project-description-video-wrapper div {
      width: 240px;
      height: 360px;
    }
}

@media (max-width: 600px) {
    .project-main-heading {
      font-size: 14.9333333333vw;
      line-height: normal;
      padding-top: 30px;
    padding-bottom: 15px;
    }
    .project-heading-line {
        width: 90%;

    }

    .project-description {
      font-size: 16px;
      line-height: 20px;
    }

    .project-description-video-wrapper {
        width: 63.6vw; /* 246px / 375px * 100 */
        height: 62.8vw; /* 243px / 375px * 100 */
        border-radius: 2.6666666667vw; /* 10px / 375px * 100 */
        margin-top: 2.6666666667vw; /* 10px / 375px * 100 */
    }

    .project-description-video-wrapper div {
        border-radius: 2.6666666667vw; /* 10px / 375px * 100 */
        width: 53.4666666667vw; /* 208px / 375px * 100 */
        height: 76.1333333333vw; /* 293px / 375px * 100 */
    }
}